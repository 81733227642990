import React from 'react'
import { type FieldValue } from 'react-hook-form'
import { ItineraryVisibility } from '#server/itinerary/models.ts'
import { FormControl, FormField, FormItem } from './ui/form.tsx'
import { Label } from './ui/label.tsx'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from './ui/select.tsx'

const ItineraryVisbilitySelectFormField = React.forwardRef<
  React.ElementRef<typeof FormField<FieldValue<any>>>,
  Omit<
    React.ComponentPropsWithoutRef<typeof FormField<FieldValue<any>>>,
    'render'
  >
>(({ ...props }, ref) => (
  <FormField
    {...props}
    render={({ field }) => (
      <FormItem className="grid grid-cols-4 items-center gap-4">
        <Label htmlFor="visibility" className="text-right">
          Privacy
        </Label>
        <Select onValueChange={field.onChange} defaultValue={field.value}>
          <FormControl id="visibility">
            <SelectTrigger className="col-span-3">
              <SelectValue placeholder="Select visibility" />
            </SelectTrigger>
          </FormControl>
          <SelectContent>
            {Object.values(ItineraryVisibility).map((visibility, i) => (
              <SelectItem key={i} value={visibility}>
                {`${visibility[0].toUpperCase()}${visibility.slice(1)}`}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </FormItem>
    )}
  />
))
ItineraryVisbilitySelectFormField.displayName =
  'ItineraryVisbilitySelectFormField'

export { ItineraryVisbilitySelectFormField }
